.button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width: 380px;
    min-width: 300px;
    border-radius: 4px;
    background-color: #003399;
    color: #ffffff;
    height: 45px;
}

.button__disabled {
    background-color: #e4e4e4;
    color: #4a4a4a;
}