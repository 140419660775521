.incidentForm {
    display: inline-flex;
    width: -webkit-fill-available;
    flex-direction: column;
}

.incidentContainer {
    flex: 1;
    padding: 0px 50px;
    flex-direction: column;
    text-align: center;
}

.incidentInputs {
    flex: 8;
    display: flex;
    flex-direction: row;
}

.incidentsSave {
    flex: 1;
    display: 'flex';
    flexDirection: 'column';
}

.incidentInputsLeft {
    flex: 1;
}

.horizontalDivider {
    display: inline-flex;
    flex-direction: column;
    height: 500px;
    margin-left: 64px;
    margin-right: 64px;
    border-right: 1px solid #979797;
}

.incidentSection {
    display: inline-flex;
    flex-direction: column;
    width: 40%;
    height: max-content;
}

.incidentsContainer {
    flex: 1;
    padding: 0px 50px;
    flex-direction: row;
    min-width: 800px;
    background-color: #ffffff;
}

.incidentsTable {
    background-color: #ffffff;
    width: 100%;
}

.incidentsTable table {
    width: 100%;
}

.incidentsTable tr {
    height: 65px;
}

.incidentsTable th {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: normal;
}

.incidentsTableHeader {
    background-color: #ffffff;
    border: none;
    border-bottom: 2px solid #3366cc;
}

.incidentsTable tbody tr {
    border: none;
    border-bottom: 1px solid rgba(0, 51, 153, 0.5);
    height: 40px;
}

.incidentsTable tbody tr:hover {
    background-color: #f4f6fb;
    border: none;
    border-bottom: 1px solid rgba(0, 51, 153, 0.5);
}

.incidentsTable tbody tr th {
    font-size: 12px;
}

.schoolsContainer {
    height: auto;
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid rgba(127, 127,127, 0.3);
    border-radius: 4px;
    padding: 8px;
    margin-top: 8px;
    margin-right: 8px;
}

.schoolCard {
    width: 100%;
    height: 32px;
    color: #003399;
    font-size: 12px;
    line-height: 32px;
    font-weight: 400;
    text-align: left;
    text-indent: 8px;
}

.schoolCard:hover {
    background-color: #F4F6FB;
    color: #2a87d0;
    font-size: 14px;
}

.schoolsReset {
    width: auto;
    display: inline-block;
    color: #d0021b;
    opacity: 0.7;
}
