.header {
    height: 65px;
    width: 100%;
    display: inline-flex;
}

.logo {
    position: absolute;
    margin-left: 32px;
    width: max-content;
    text-align: left;
}

.profile {
    display: block;
    margin-right: 32px;
    margin-left: auto;
    margin-top: 10px;
    width: max-content;
    text-align: left;
}
