@import 'buttons.css';
@import 'followUps.css';
@import 'forms.css';
@import 'header.css';
@import 'incidents.css';
@import 'login.css';
@import 'modals.css';
@import 'sideMenu.css';
@import 'signUp.css';
@import 'texts.css';
@import 'toast.css';
@import 'errors.css';


div {
  /*border: 1px solid #000000;*/
}

body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#root {
  height: 100%;
  display: flex;
}

#applicationContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  flex: 1;
}


/* BACKGROUNDS */

.imageBackground {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.imageBackground__signUp {
  background-position: left;
}

.overlay__blue {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 51, 153, 0.7);
  overflow: hidden;
}

/* COMPONENTS */

.componentContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  height: -webkit-fill-available;
}

.componentSection {
  flex: 1;
  display: inline-flex;
  height: 80%;
}

.row {
  flex-direction: row;
}
