.sideMenu {
    flex: 1;
    display: flex;
    height: -webkit-fill-available;
    flex-direction: column;
    min-width: 275px;
    max-width: 400px;
    background-color: #f4f6fb;
    position: relative;
}

.sideMenu__subTitle {
    padding: 23px;
    position: relative;
    flex-direction: row;
}

.sideMenu__item {
    display: flex;
    background-color: #ffffff;
    position: relative;
    flex-direction: row;
    height: 60px;
    align-content: center;
}

.sideMenu__subItem {
    margin-left: 45px;
}

.sideMenu__item_title {
    line-height: 60px;
    text-align: left;
    margin-left: 60px;
    margin-right: auto;
}

.sideMenu__item_version {
    font-size: smaller;
    position: absolute;
    bottom: 20px;
    left: 175px;
}
