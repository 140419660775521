/* FORMS */

.createUser {
    flex: 1;
    flex-direction: row;
    margin: auto;
    text-align: center;
}

.createSchoolInputs {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.pickerInput > * > * > * {
    height: 35px;
    border: 1px solid grey;
    border-radius: 4px;
    justify-content: flex-start;
    text-align: center;
    display: flex;
    padding: 16px;
}

.react-datepicker-wrapper {
    display: flex !important;
}

.react-datepicker__input-container input {
    border: none;
    border-bottom: 1px solid rgba(111,111,111,0.3);
    text-align: left;
 }

.react-datepicker__month-container {
    float: none !important;
    display: table !important;
}

.dropdownInput {
    height: 35px;
    width: 100px;
    background-color: white;
    display: flex;
    border: none;
    border-bottom: 1px solid rgba(111,111,111,0.3);
    text-align: left;
}

.formContainer {
    background-color: #ffffff;
    min-width: 470px;
    min-height: 500px;
    width: 30%;
    height: 65%;
    margin: 10% auto;
    border-radius: 4px;
    position: relative;
}

.formButtonContainer {
    position: relative;
    bottom: -15%;
    margin-left: auto;
    margin-right: auto;
}

.formError {
    display: block;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.formError__text {
    padding-top: 15px;
    text-align: center;
    color: #D0021B
}

.formInput {
    display: block;
    width: 50%;
    min-width: 370px;
    height: 40px;
    border: none;
    border-bottom: 1px solid #979797;
    text-indent: 23px;
    margin-left: auto;
    margin-right: auto;
}

.reduxFormRow {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}

.reduxFormInput {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.inputWithTitle {
    display: flex;
    height: 36px;
    flex-direction: row;
    border: none;
    border-bottom: 1px solid rgba(111,111,111,0.3);
}

.inputWithTitle__title {
    flex-direction: row;
    color: gray;
    font-size: 11px;
    margin-top: -2px;
}

.loginFormGroup {
    display: flex;
    flex: 1;
    flex-direction: column;
}

#loginFormContainer {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 500px;
    min-height: 550px;
    margin: 10% auto;
    border-radius: 4px;
    position: relative;
    padding-bottom: 40px;
}

@media screen and (max-width: 500px) {
    #loginFormContainer {
        min-width: 1px;
        width: 100%;
        height: 100%;
        margin: auto;
    }
}
