.signUpContainer {
    width: 55%;
    margin-right: 0;
    margin-top: 0;
    height: 100%;
}

.signUpFormInputRow {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    width: 80%;
}

@media screen and (max-width: 500px) {
    .signUpContainer {
        width: 100%;
        height: 100%;
        margin: auto;
    }
}
