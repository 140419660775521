.submitError {
    min-width: 600px;
    background-color: #F56D6A;
    border-color: #99413E;
    border-width: 2px;
    color: #ffffff;
    border-radius: 4px;
    padding: 10px;
    margin-top: 50px;
}

.submitErrorMessage {
    word-wrap: break-word;
    padding-bottom: 6px;
}
