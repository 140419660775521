.modal {
    display: contents;
    margin-left: auto;
    margin-right: auto;
}

.modal-dialog {
    min-width: 800px;
}

.modal__header {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: #003399;
    text-align: center;
    width: auto;
    height: 79px;

}

.modal__headerText {
    width: 100%;
    display: inline-flex;
    position: relative;
    margin-left: 28px;
    padding-top: 10px;
    color: #ffffff;
    text-align: left;
    height: 42px;
    font-weight: 200;
    font-size: 24px;
}

.modal__headerSubText {
    width: 100%;
    display: inline-flex;
    position: relative;
    margin-left: 28px;
    color: #ffffff;
    text-align: left;
    height: 42px;
    font-weight: 200;
    font-size: 12px;
}

.modal__bodyContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 32px;
    min-height: 350px;
}

.modal__inputLabel {
    font-size: 18px;
    font-weight: 100;
    margin-right: 35px;
}

.modal__formGroup {
    flex-direction: row;
    display: flex;
    width: 75%;
    justify-content: flex-start;
    margin: 15px;
}

.modal__ctaContainer {
    margin-top: 25px;
}

.modal__closeButton {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    font-size: 20px;
    color: #003399;
    background-color: rgba(255, 255, 255, .5);
    border-color: #003399;
    align-items: flex-end;
    justify-content: flex-end;
}
