.toast__container {
    display: inline-flex;
    z-index: 10000;
    width: 100%;
    text-align: left;
    padding: 12px;
}

.toast__messageContent {
    width: inherit;
    text-indent: 12px;
}

.closeButton {
    background-color: #ffffff;
    border-radius: 32px;
    border: 1px solid #aeaeae;
    padding: 4px;
    position: absolute;
    right: -6px;
    top: -6px;
    width: 22px;
    height: 22px;
}

.arrow {
    margin-top: 8px;
    height: 35px;
    width: 40px;
    padding: 3px;
    margin-right: 4px;
    position: relative;
}

.toast-success {
    background-color: #B3EAB3;
    color: #0E4D0F;
    border-color: #82C980;
}

.toast-error {
    background-color: #F56D6A;
    color: #ffffff;
    border-color: #99413E;
}


