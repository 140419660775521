.appText {
    color: #4a4a4a;
    width: 100%;
}

.appText:hover {
    text-decoration: none;
}

.errorText {
    display: inline-block;
    text-align: center;
    color: #d0021b;
}

.appTitle {
    position: relative;
    width: 100%;
    height: 175px;
    text-align: center;
}

.authLink {
    margin-top: 31px;
}

.signUpTitle {
    height: 300px;
    width: auto;
    text-align: center;
}

.appTitle p {
    color: #003399;
    font-size: 40px;
    font-weight: normal;
    line-height: 175px; /* MUST MATCH HEIGHT OF .appTitle to align correctly */
}

.signUpTitle p {
    font-size: 32px;
}

.linkText {
    display: block;
    width: 100%;
    color: #3366cc !important;
    font-size: 15px !important;
    text-align: center !important;
}

.linkText:hover {
    color: #6699ff !important;
    text-decoration: none !important;
}

.pageLink {
    cursor: pointer;
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #003399;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.pageLink:hover {
    cursor: pointer;
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #fff;
    background-color: #3366cc;
    border: 1px solid #3366cc;
}

.pageLink__active {
    cursor: pointer;
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #fff;
    background-color: #003399;
    border: 1px solid #003399;
}

.pageLink__disabled {
    cursor: not-allowed;
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #4a4a4a;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.logo__text {
    font-size: 32px;
    color: #003399;
}

.text__grey {
    color: #4a4a4a;
    opacity: 0.5;
}

.text__ex_sm {
    font-size: 12px;
}

.text__md {
    font-size: 18px;
}

.text__left {
    text-align: left;
}

.text__right {
    text-align: right;
}

.sectionTitle {
    margin-bottom: 26px;
}

.createSchoolSectionText {
    padding-top: 20px;
    margin-bottom: 5px;
}
